/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ChannelType {
    NONE = 'none',
    BANNER = 'banner',
    VIDEO = 'video',
    FACEBOOK_INSTAGRAM = 'facebook-instagram',
    CONNECTED_TV = 'connected-tv',
    AUDIO = 'audio',
    EMAIL = 'email',
    SEARCH = 'search',
    TWITTER = 'twitter',
    DATA_ONLY = 'data-only',
    MEASUREMENT = 'measurement',
    LINKED_IN = 'linked-in',
    EXTERNAL_DSP = 'external-dsp',
    TIK_TOK = 'tik-tok',
    SNAPCHAT = 'snapchat',
    XANDR_DMP = 'xandr-dmp',
    ENDEMIC_HCP = 'endemic-hcp',
    TRADE_DESK_DMP = 'trade-desk-dmp',
    LIVERAMP = 'liveramp',
    DIRECT_SOLD = 'direct-sold',
    ENDEMIC_DTC = 'endemic-dtc',
    REDDIT = 'reddit',
    YAHOO = 'yahoo',
    ADTHEORENT = 'adtheorent',
    ENDEMIC_EHR = 'endemic-ehr',
}
