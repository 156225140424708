/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReportingFieldId {
    CAMPAIGN_ID = 'campaign-id',
    CAMPAIGN = 'campaign',
    CREATIVE_ID = 'creative-id',
    CREATIVE = 'creative',
    AD_GROUP_FLIGHT = 'ad-group-flight',
    AD_GROUP_FLIGHT_ID = 'ad-group-flight-id',
    AD_GROUP = 'ad-group',
    AD_GROUP_ID = 'ad-group-id',
    AD_GROUP_FLIGHT_CODE = 'ad-group-flight-code',
    AD_GROUP_TRACKING_CODE = 'ad-group-tracking-code',
    ADVERTISER_ID = 'advertiser-id',
    ADVERTISER = 'advertiser',
    AVERAGE_FREQUENCY = 'average-frequency',
    AVG_FREQUENCY_BY_HCP = 'avg-frequency-by-hcp',
    BRAND = 'brand',
    BUDGET_CAP = 'budget-cap',
    BUDGET_TYPE = 'budget-type',
    CAMPAIGN_COMMENTS = 'campaign-comments',
    CAMPAIGN_TRACKING_CODE = 'campaign-tracking-code',
    CITY = 'city',
    CLICKS = 'clicks',
    CREATIVE_SIZE = 'creative-size',
    CREATIVE_TRACKING_CODE = 'creative-tracking-code',
    CTR = 'ctr',
    DATA_COST = 'data-cost',
    DATA_CPM = 'data-cpm',
    DAY = 'day',
    DEAL = 'deal',
    DEAL_ID = 'deal-id',
    DELIVERED = 'delivered',
    DEVICE_TYPE = 'device-type',
    DIRECT_SOLD_SPEND = 'direct-sold-spend',
    DMA = 'dma',
    END_DATE = 'end-date',
    FEES = 'fees',
    HOUR = 'hour',
    IMPRESSIONS = 'impressions',
    IMPRESSION_GOAL = 'impression-goal',
    MAKER = 'maker',
    MARKETER = 'marketer',
    MARKETER_ID = 'marketer-id',
    MEDIA_COST = 'media-cost',
    MEDIA_COST_PER_VIDEO_COMPLETE = 'media-cost-per-video-complete',
    MEDIA_CPM = 'media-cpm',
    MEDIA_TYPE = 'media-type',
    MONTH = 'month',
    OPERATING_SYSTEM = 'operating-system',
    POST_CLICK_CONVERSIONS = 'post-click-conversions',
    POST_VIEW_CONVERSIONS = 'post-view-conversions',
    POSTAL_CODE = 'postal-code',
    QUARTER = 'quarter',
    SELLER_MEMBER = 'seller-member',
    SELLER_MEMBER_ID = 'seller-member-id',
    SENT = 'sent',
    SERVED_VIDEOS = 'served-videos',
    SKIPPED_VIDEOS = 'skipped-videos',
    SITE_DOMAIN = 'site-domain',
    SPEND = 'spend',
    SPEND_PER_VIDEO_COMPLETE = 'spend-per-video-complete',
    START_DATE = 'start-date',
    STATE = 'state',
    TOTAL_CPM = 'total-cpm',
    UNIQUE_HCPS = 'unique-hcps',
    UNIQUES = 'uniques',
    UNSUBSCRIBED = 'unsubscribed',
    VIDEO100 = 'video100',
    VIDEO25 = 'video25',
    VIDEO50 = 'video50',
    VIDEO75 = 'video75',
    VIDEO_COMPLETION_RATE = 'video-completion-rate',
    VIDEO_PLAY_ERRORS = 'video-play-errors',
    VIEWABILITY = 'viewability',
    WEEK = 'week',
    YEAR = 'year',
}
