/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReportingCategoryId {
    LEVEL_OF_DETAIL = 'level_of_detail',
    REFINEMENTS = 'refinements',
    DELIVERY = 'delivery',
    TIME = 'time',
    FINANCIAL = 'financial',
}
