/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReportType {
    CAMPAIGN_PERFORMANCE = 'campaign-performance',
    CAMPAIGN_SUMMARY = 'campaign-summary',
    SITE_PERFORMANCE = 'site-performance',
    CUSTOM_MATCH = 'custom-match',
    SELLER_PERFORMANCE = 'seller-performance',
}
